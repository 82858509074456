@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@400;500;600;700&display=swap");

* {
  scroll-behavior: smooth;
}

#intro {
  display: flex;
  color: white;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

#title {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  font-size: 50px;
  letter-spacing: 10px;
  margin-top: -60px;
  padding-left: 10px;
}
#title span {
  background: -webkit-linear-gradient(white, #38495a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

button {
  background-color: #ea0023;
  border-radius: 15%;
  font-family: Montserrat, sans-serif;
  font-size: medium;
  font-weight: 600;
}
#button {
  color: white;
}
#buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
#button1 {
  appearance: none;
  background-color: #ffffff;
  border-radius: 0;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-family: Montserrat, sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1rem;
  margin-right: 1rem;
  opacity: 1;
  outline: 0;
  padding: 1rem 2rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-rendering: geometricprecision;
  text-transform: uppercase;
  transition: opacity 300ms cubic-bezier(0.694, 0, 0.335, 1),
    background-color 100ms cubic-bezier(0.694, 0, 0.335, 1),
    color 100ms cubic-bezier(0.694, 0, 0.335, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

#button1:before {
  animation: opacityFallbackOut 0.5s step-end forwards;
  backface-visibility: hidden;
  background-color: red;
  opacity: 0.5;
  clip-path: polygon(-1% 0, 0 0, -25% 100%, -1% 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  transition: clip-path 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-clip-path 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 100%;
}

#button1:hover:before {
  animation: opacityFallbackIn 0s step-start forwards;
  clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
}

#button1:after {
  background-color: #ffffff;
}

#button1 span {
  z-index: 1;
  position: relative;
}
#button2 {
  appearance: none;
  background-color: transparent;
  border: 2px solid #1a1a1a;
  border-radius: 15px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: Montserrat, sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  /* min-height: 2rem; */
  min-width: 0;
  outline: none;
  padding: 0.8rem 1.9rem;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
  margin-left: 1rem;
}
#button2:disabled {
  pointer-events: none;
}

#button2:hover {
  color: white;
  background-color: red;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

#button2:active {
  box-shadow: none;
  transform: translateY(0);
}
#suc_logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
